html,
body {
  height: auto;
  min-height: 100vh;
  font-family: "Roboto", Arial, sans-serif;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  line-height: 2.8rem;
}

.kud-Wrapper {
  display: flex;
}

.kud-Gorgeous {
  content: "";
  flex: 1 40%;
  background-image: url("https://www.gravatar.com/avatar/e6eaeaa6da69e804c27c2d4cd55107e0?s=1024");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transition: flex 200ms ease;
}

/*@media (min-width: 840px) {*/
/**/
/*.kud-Gorgeous:hover {*/
/*  flex: 1 80%;*/
/*  transition: flex 200ms ease;*/
/*}*/
/**/
/*}*/

.kud-Content {
  flex: 1 60%;
  height: 100vh;
  overflow-y: auto;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 1.9rem;
  line-height: 1.6;
}

.kud-Content-wrapper {
  max-width: 70rem;
  padding: 2rem 5rem;
  margin: auto;
}

.kud-Content a {
  text-decoration: none;
  color: #222;
  position: relative;
  font-weight: 600;
}

.kud-Content a::after {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 1px;
  background: #222;
  content: "";
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
  transform: translateY(5px);
}

.kud-Content a:hover::after,
.kud-Content a:focus::after {
  opacity: 1;
  transform: translateY(0px);
}

.kud-Content-heading {
  font-family: "Lusitana", serif;
  color: #2e2e2e;
  text-align: center;
  font-size: 3rem;
  line-height: 1.3;
  margin-bottom: 7rem;
  padding: 0 3rem;
}

.kud-Content-heading:first-letter {
  font-weight: 600;
}

.kud-Content-heading a {
  color: #ed4545;
}

.kud-Content-heading a::after {
  background: #ed4545;
  height: 2px;
}

.kud-Links {
  padding: 0;
  margin: 0;
  margin-top: 5rem;
  text-align: center;
}

.kud-Links ul {
  padding: 0;
  margin: 0;
  margin-left: -5px;
}

.kud-Links li {
  display: inline-block;
  margin: 5px;
}

.kud-Links li a {
  background: #151515;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.6rem;
  height: 4.6rem;
  position: relative;
  border-radius: 50%;
  font-size: 28px;
  color: white;
  text-decoration: none;
  text-align: center;
  line-height: 2.4rem;
  transition: all 0.4s ease;
  border: 1px solid black;
}

.kud-Links li a:after {
  display: none;
}

.kud-Links li a svg {
  display: block;
  width: 3rem;
  height: 3rem;
  fill: #fff;
}

.kud-Links li a span {
  display: none;
}

.kud-Links li a:hover {
  background: white;
  color: #151515;
}

.kud-Links li a:hover svg {
  fill: #000;
}

@media (max-width: 839px) {
  .kud-Wrapper {
    display: block;
  }

  .kud-Wrapper::before {
    width: 10rem;
    height: 10rem;
    margin: auto;
    display: block;
    border-radius: 50%;
    /* border: 3px solid #fff; */
    /* box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3); */
    border: 1px solid #cccccc;
    margin-top: 3rem;
  }

  .kud-Content {
    display: block;
    height: auto;
  }
}

@media (max-width: 379px) {
  .kud-Content-wrapper {
    padding: 1rem;
  }

  .kud-Content-heading {
    font-size: 2.7rem;
  }
}
